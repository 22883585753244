import React, {useCallback, useRef, useState} from 'react';
import L, {latLng} from 'leaflet';
import "@maptiler/sdk/dist/maptiler-sdk.css";
import './ParcelMap.scss';
import {ParcelData} from "@/services/api.service.ts";
import {MapContainer, TileLayer, ZoomControl} from "react-leaflet";
import classnames from "classnames";
import {ParcelsMarkers} from "@/components/maps/ParcelsMarkers.tsx";
import {ScoreStats} from "@/types";

const apiKey = import.meta.env.VITE_APP_MAPTILER_API_KEY;
const atlanta: [number, number] = [33.7490, -84.3880]; // Latitude and Longitude for Atlanta, GA
const baseClassName = 'parcel-map';

type ParcelMapProps = {
    className?: string;
    parcels?: ParcelData[];
    searching?: boolean;
    empty?: boolean;
}

function ParcelMap({parcels, searching = false, empty = true, className}: ParcelMapProps) {

    const mapContainer = useRef(null);
    const zoom = 14;
    // const [parcelFeatures, setParcelFeatures] = useState<ParcelFeature[]>([])
    const [popupInfo, setPopupInfo] = useState<ScoreStats & {position: L.LatLng} | undefined>();

    // useEffect(() => {
    //     if (searching) {
    //         setParcelFeatures([]);
    //     }
    // }, [searching])
    // useEffect(() => {
    //
    //     // setParcelFeatures(parcels?.map(p => {
    //     //     const feature = turf.feature(p.geometry, {...p, geometry: undefined})
    //     //     // feature.geometry = turf.centroid(feature).geometry;
    //     //     return feature as any;
    //     // }) ?? []);
    // }, [parcels]);

    const handleClusterHoverStart = useCallback((latLng: L.LatLng, data: ScoreStats): void => {

        if (data) {
            setPopupInfo({
                position: latLng,
                ...data
            });
        } else {
            setPopupInfo(undefined)
        }
    }, []);

    const handleClusterHoverEnd = useCallback(() => {
        setPopupInfo(undefined)
    }, []);

    return (
        <div ref={mapContainer} className={classnames(baseClassName, className, {
            [`${baseClassName}--empty`]: empty === true,
            [`${baseClassName}--searching`]: searching
        })}>
            <MapContainer
                center={latLng(atlanta)} // Initial center; will be adjusted by FitBounds
                zoom={zoom} // Initial zoom; will be adjusted by FitBounds
                zoomControl={false}
                style={{height: '100%', width: '100%'}}
            >
                <ZoomControl position={'topright'}/>
                <TileLayer
                    url={`https://api.maptiler.com/maps/dataviz-light/{z}/{x}/{y}.png?key=${apiKey}`}
                    attribution={`\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e`}
                    tileSize={512}
                    zoomOffset={-1}
                    minZoom={1}
                    crossOrigin={true}
                />

                {!searching && <ParcelsMarkers parcels={parcels}
                                               hoverDelay={150}
                                               onHoverStart={handleClusterHoverStart}
                                               onHoverEnd={handleClusterHoverEnd}/>
                }

                {/*{popupInfo && (*/}
                {/*    <ErrorBoundary>*/}
                {/*    <ClusterSummaryInfo position={popupInfo.position} stats={popupInfo} hov/>*/}
                {/*    </ErrorBoundary>*/}
                {/*)}*/}
            </MapContainer>
        </div>
    )
}

export default ParcelMap;
