import pinGreen from "@images/pins/pin-green.svg";
import pinOrange from "@images/pins/pin-orange.svg";
import pinYellow from "@images/pins/pin-yellow.svg";
import pinRed from "@images/pins/pin-red.svg";
import L, {DivIcon, Icon, LeafletMouseEvent, MarkerCluster} from "leaflet";
import classnames from "classnames";
import React, {useEffect, useRef} from "react";
import MarkerClusterGroup from "react-leaflet-cluster";
import {Marker} from "react-leaflet";
import ParcelSummaryPopup from "@/components/maps/ParcelSummaryPopup.tsx";
import {FitBounds} from "@/components/maps/FitBounds.tsx";
import {ParcelData} from "@/services/api.service.ts";

const baseClassName = 'parcel-map';

const iconsByScore: Record<number, string> = {
    60: pinGreen as string,
    59: pinOrange as string,
    58: pinYellow as string,
    57: pinRed as string
};
const DEFAULT_ICON_URL: string = pinRed as string;

type ParcelMarkerProps = {
    parcels?: ParcelData[];
    onHoverStart?(latLng: L.LatLng, data: Record<string, any>): void;
    onHoverEnd?(): void;
    hoverDelay?: number;
}

export function ParcelsMarkers({parcels, onHoverStart, onHoverEnd, hoverDelay = 1000}: ParcelMarkerProps) {

    const hovering = useRef<boolean>(false);

    useEffect(() => {
        return () => {
            onHoverEnd?.();
        }
    }, [])

    function handleMouseOver(e: LeafletMouseEvent) {

        if (!hovering.current) {
            hovering.current = true;

            setTimeout(() => {

                if(!hovering.current) return;

                const cluster: MarkerCluster = e.sourceTarget;
                const allScores = cluster.getAllChildMarkers().map(m => (m.options as any).score);
                const stats = {
                    total: allScores?.length ?? 0,
                    high: allScores?.filter(v => v === 60).length ?? 0,
                    mediumHigh: allScores?.filter(p => p === 59).length ?? 0,
                    medium: allScores?.filter(p => p === 58).length ?? 0,
                    low: allScores?.filter(p => p <= 57).length ?? 0,
                }

                onHoverStart?.(e.latlng, stats);
            }, hoverDelay);
        }
    }

    function handleMouseOut(e: LeafletMouseEvent) {

        console.log('out event')
        if(hovering.current) {
            hovering.current = false;
            onHoverEnd?.();
        }
    }

    // @ts-ignore
    return <>
        <MarkerClusterGroup iconCreateFunction={createIcon} chunkedLoading
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            showCoverageOnHover={false}>
            {parcels?.map(({geometry, ...properties}, index) => (
                <Marker
                    icon={new Icon({
                        iconSize: [30, 39],
                        iconAnchor: [15, 39],
                        popupAnchor: [0, -40],
                        tooltipAnchor: [15, -20],
                        iconUrl: iconsByScore[properties.lihtcScore] ?? DEFAULT_ICON_URL
                    })}
                    // @ts-ignore
                    score={properties.lihtcScore}
                    key={index}
                    position={[geometry.coordinates[1], geometry.coordinates[0]]}
                >
                    <ParcelSummaryPopup parcel={properties}/>
                </Marker>
            ))}
        </MarkerClusterGroup>

        <FitBounds parcels={parcels}/>

    </>
}

function createIcon(cluster: MarkerCluster): DivIcon {

    return L.divIcon({
        html: `<div><span>${cluster.getChildCount()}</span></div>`,
        className: classnames(`${baseClassName}__cluster`),
        iconSize: L.point(60, 60, true),
    });
}
