import './ParcelSearch.scss';
import React, {useState} from "react";
import classnames from "classnames";
import {findParcels, ParcelData} from "../../services/api.service.ts";
import {ParcelSearchInput} from "../ParcelSearchInput";
import ParcelMap from "../maps/ParcelMap.tsx";
import SearchingMask from "@/components/ParcelSearch/SearchingMask.tsx";
import SearchStats from "@/components/ParcelSearch/SearchStats.tsx";

export interface ParcelSearchProps extends React.HTMLAttributes<HTMLDivElement> {

}

const baseClassName = 'parcel-search';

export function ParcelSearch({className, ...props}: ParcelSearchProps) {
    const [parcels, setParcels] = useState<ParcelData[]>([]);
    const [searching, setSearching] = useState<boolean>(false);

    async function handleSearch(text: string): Promise<void> {

        try {
            setSearching(true);
            setParcels(await findParcels(text))
        } finally {
            setSearching(false);
        }
    }

    function handleClear() {
        setParcels([]);
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <header className={`${baseClassName}__header`}>
            <ParcelSearchInput onSearch={handleSearch} onClear={handleClear} className={`${baseClassName}__search`}/>
            <SearchStats parcels={parcels} className={`${baseClassName}__stats`}/>
        </header>
        <main className={`${baseClassName}__content`}>
            <ParcelMap className={`${baseClassName}__map`} parcels={parcels} empty={!parcels?.length} searching={searching}/>
            <SearchingMask className={`${baseClassName}__mask`} message={'Locating Parcels'} searching={searching}/>
        </main>
    </div>
}

export default ParcelSearch;
