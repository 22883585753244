import './SearchStats.scss';
import React, {useMemo} from "react";
import classnames from "classnames";
import {ParcelData} from "@/services/api.service.ts";
import {ScoreStats} from "@/types";

export type ParcelSearchSummaryProps = {
    parcels?: ParcelData[]
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'parcel-search-stats';

export function SearchStats({className, parcels, ...props}: ParcelSearchSummaryProps) {

    const stats: ScoreStats = useMemo<ScoreStats>(() => ({
        total: parcels?.length ?? 0,
        high: parcels?.filter(p => p.lihtcScore === 60).length ?? 0,
        mediumHigh: parcels?.filter(p => p.lihtcScore === 59).length ?? 0,
        medium: parcels?.filter(p => p.lihtcScore === 58).length ?? 0,
        low: parcels?.filter(p => p.lihtcScore <= 57).length ?? 0,
    }), [parcels])

    return <div className={classnames(baseClassName, className)} {...props}>
        {parcels && <>
            <SearchStat value={stats.total} type={'total'}/>
            <SearchStat value={stats.high} type={'high'}/>
            <SearchStat value={stats.mediumHigh} type={'medium-high'}/>
            <SearchStat value={stats.medium} type={'medium'}/>
            <SearchStat value={stats.low} type={'low'}/>
        </>}
    </div>
}

export type SearchStatProps = {
    value?: number;
    type?: 'total' | 'high' | 'medium-high' | 'medium' | 'low';
} & React.HTMLAttributes<HTMLDivElement>;

function SearchStat({value = 0, type}: SearchStatProps) {

    const suffix: string = useMemo(() => {

        const suffix = value === 1 ? '' : 's';
        if (type === 'total') {
            return `Total Site${suffix}`
        } else {
            return `Site${suffix}`
        }
    }, [type, value]);

    return <div className={classnames(`${baseClassName}__stat`, `${baseClassName}__stat--${type}`)}>
        <div className={`${baseClassName}__stat-value`}>{value}</div>
        <div className={`${baseClassName}__stat-suffix`}>{suffix}</div>
    </div>
}

export default SearchStats;
