import './App.css'
import {ParcelSearch} from "./components/ParcelSearch";

function App() {

    return <div>
        <ParcelSearch/>
    </div>
}

export default App
