import './styles/index.scss';
import {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import ErrorBoundary from "./components/ErrorBoundary.tsx";


createRoot(document.getElementById('root')!).render(
  <StrictMode>
      {/*<ErrorBoundary>*/}
          <App />
      {/*</ErrorBoundary>*/}
  </StrictMode>,
)
