import './ParcelSummaryPopup.scss';
import React from "react";
import classnames from "classnames";
import {ParcelData} from "../../services/api.service.ts";
import {Popup} from "react-leaflet";

export interface ParcelSummaryPopupProps extends React.HTMLAttributes<HTMLDivElement> {
    parcel: Omit<ParcelData, 'geometry'>;
}

const baseClassName = 'parcel-summary-popup';
const properties: (keyof ParcelData)[] = ['city', "lihtcScore",]

export function ParcelSummaryPopup({className, parcel, ...props}: ParcelSummaryPopupProps) {

    return <Popup className={classnames(baseClassName, className, {
        [`${baseClassName}--score-high`]: parcel.lihtcScore === 60,
        [`${baseClassName}--score-medium-high`]: parcel.lihtcScore === 59,
        [`${baseClassName}--score-medium`]: parcel.lihtcScore === 58,
        [`${baseClassName}--score-low`]: parcel.lihtcScore < 58
    })} {...props}>
        <header className={`${baseClassName}__header`}>{parcel.address}</header>
        <main className={`${baseClassName}__values`}>
            <div className={`${baseClassName}__prop`}>
                <div className={`${baseClassName}__prop-label`}>
                    Geo Score
                </div>
                <div className={`${baseClassName}__prop-value`}>
                    {parcel.lihtcScore}/60
                </div>
            </div>
            <div className={`${baseClassName}__prop`}>
                <div className={`${baseClassName}__prop-label`}>
                    Market Status
                </div>
                <div className={`${baseClassName}__prop-value`}>
                    {parcel.marketStatus}
                </div>
            </div>
            <div className={`${baseClassName}__prop`}>
                <div className={`${baseClassName}__prop-label`}>
                    Current Zoning
                </div>
                <div className={`${baseClassName}__prop-value`}>
                    {parcel.zoning}
                </div>
            </div>
            <div className={`${baseClassName}__prop`}>
                <div className={`${baseClassName}__prop-label`}>
                    Rezoning Likelihood
                </div>
                <div className={`${baseClassName}__prop-value`}>
                    {parcel.rezoningScore}
                </div>
            </div>
            <div className={`${baseClassName}__prop`}>
                <div className={`${baseClassName}__prop-label`}>
                    Estimated Max Units
                </div>
                <div className={`${baseClassName}__prop-value`}>
                    {parcel.maxUnits}
                </div>
            </div>
            <div className={`${baseClassName}__prop`}>
                <div className={`${baseClassName}__prop-label`}>
                    Max Annual Rent
                </div>
                <div className={`${baseClassName}__prop-value`}>
                    {parcel.maxRent}
                </div>
            </div>
            <div className={`${baseClassName}__prop`}>
                <div className={`${baseClassName}__prop-label`}>
                    Proposed Usage
                </div>
                <div className={`${baseClassName}__prop-value`}>
                    {parcel.usage}
                </div>
            </div>
            <div className={`${baseClassName}__prop`}>
                <div className={`${baseClassName}__prop-label`}>
                    Acreage
                </div>
                <div className={`${baseClassName}__prop-value`}>
                    {parcel.size} acres
                </div>
            </div>
        </main>
        <footer className={`${baseClassName}__footer`}>
            <button>Save Property</button>
        </footer>
    </Popup>
}

export default ParcelSummaryPopup;
