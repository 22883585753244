import axios from "axios";
import {LatLngBoundsExpression} from "leaflet";

export interface ParcelData {
    geometry: any; // GeoJSON as a string
    address: string;
    city: string;
    state: string;
    nearest: any[];
    lihtcScore: number;
    pScore: number;
    size: number;
    usage: 'family' | 'senior';
    maxRent: number;
    maxUnits: number;
    rezoningScore: number;
    zoning: string;
    value: number;
    marketStatus: string;
}

const baseUrl = import.meta.env.VITE_APP_API_BASE_URL;
const http = axios.create({
    baseURL: baseUrl
})

export async function findParcels(query: string): Promise<ParcelData[]> {

    const {data} = await http.post<ParcelData[]>(`/parcels.search`, {
        query
    }); // Adjust payload if needed

    return data;
}
