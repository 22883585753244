import './SearchingMask.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";

export interface SearchingMaskProps extends React.HTMLAttributes<HTMLDivElement> {
    searching?: boolean;
    message?: string;
}

const baseClassName = 'searching-mask';

export function SearchingMask({className, message = 'Searching ...', searching = false, ...props}: SearchingMaskProps) {

    if(!searching) return null;

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__message`}>{message}</div>
    </div>
}

export default SearchingMask;
