import './ParcelSearchInput.scss';
import React, {useState} from "react";
import classnames from "classnames";

export interface ParcelSearchInputProps extends React.HTMLAttributes<HTMLDivElement> {
    onSearch?(text: string): void | Promise<void>;

    onClear?(): void | Promise<void>;
}

const baseClassName = 'parcel-search-input';

export function ParcelSearchInput({className, onSearch, onClear, ...props}: ParcelSearchInputProps) {

    const [value, setValue] = useState<string>('');

    function handleEnter(e: React.KeyboardEvent): void {
        if (e.key === 'Enter' && value?.trim()) {
            onSearch?.(value?.trim())
        }
    }

    function handleClear() {
        setValue('');
        onClear?.();
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <input className={`${baseClassName}__input`} value={value} onChange={e => setValue(e.target.value)}
               onKeyDown={handleEnter}
               placeholder={'Find affordable housing sites...'}/>
        <button className={`${baseClassName}__clear`} onClick={handleClear}/>
    </div>
}

export default ParcelSearchInput;
