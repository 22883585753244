import {useMap} from "react-leaflet";
import {useEffect} from "react";
import L from "leaflet";
import {ParcelData} from "@/services/api.service.ts";

export type FitBoundsProps = {
    parcels?: ParcelData[];
}

export function FitBounds({parcels}: FitBoundsProps) {

    const map = useMap();

    useEffect(() => {
        if (!parcels?.length) return;

        const geoJsonLayer = L.geoJSON(parcels.map(p => p.geometry));
        const bounds = geoJsonLayer.getBounds();

        if (bounds.isValid()) {
            map.fitBounds(bounds, {padding: [150, 150]});
        } else {
            console.warn('GeoJSON layer has invalid bounds.');
        }
    }, [parcels, map]);

    return null;
}
